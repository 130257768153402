// import 'react-app-polyfill/ie11'; // For IE 11 support
// import 'react-app-polyfill/stable';
// import './polyfill'
// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// import { icons } from './assets/icons'

// import { Provider } from 'react-redux'
// import store from './store'

// React.icons = icons

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App/>
//     </Provider>
//   </React.StrictMode>, 
//   document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./store";
import { persistStore, persistReducer } from "redux-persist";
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'
// import store from './store'
import storage from "redux-persist/lib/storage";
import { PersistGate } from   "redux-persist/integration/react";

const persistConfig = {
  key: "root",
  storage,
};

React.icons = icons

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>, 
  document.getElementById('root')
);
serviceWorker.unregister();
