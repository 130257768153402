import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { appStatusAction } from "../../store";

function Notification(props) {
  const [open, setOpen] = useState(false);

  const { message } = props;
  // console.log(message,"im here in notification page")
  //   const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  useEffect(() => {
    if (message && message !== "") 
     setOpen(true);
  }, [message]);

  //   useEffect(() => {
  //     if (!open) dispatch(appStatusAction(""));
  //   }, [open, dispatch]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        key={"top center"}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
}

export default Notification;
