import React from "react";
import { axiosClient } from "./helper";
import { appStatusAction } from "./store";
// import { appStatusAction } from './store';
import Cookie from "cookie-universal";
import { Redirect } from "react-router-dom";

const cookies = Cookie();

function loginAction(token, id, username, email, role) {
  return {
    type: "LOGIN",
    accessToken: token,
  };
}

export function UserLogin(data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`adminLogin`, data);
      cookies.set("accessToken", res.data.token);
      // dispatch(appStatusAction("Offers Successfully Deleted"));
      // localStorage.setItem("token", res.data.token);
      window.location.reload();
    } catch (err) {
      alert("server error signin", err);
      console.log(err);
    }
  };
}
function CreateUserAction(data) {
  return {
    type: "USERS",
    user: data,
  };
}

export function createUser(data) {
  console.log(data);
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`createUserByAdmin`, data);
      dispatch(CreateUserAction(res.data));
    } catch (err) {
      alert(" server error");
      console.log(err);
    }
  };
}
function CreatecompanyAction(data) {
  return {
    type: "COMPANY",
    company: data,
  };
}

export function createcompany(data) {
  console.log(data);
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`addCompanyByAdmin`, data);
      dispatch(CreatecompanyAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function updatecompanyAction(data) {
  return {
    type: "UPDATECOMPANY",
    updatecompany: data,
  };
}

export function updatecompany(id, data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().put(`updateCompanyById/${id}`, data);
      dispatch(updatecompanyAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function addCategoryAction(data) {
  return {
    type: "CATEGORY",
    category: data,
  };
}
export function addCategory(data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`addCategory`, data);
      dispatch(addCategoryAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function addJObAction(data) {
  return {
    type: "JOB",
    job: data,
  };
}
export function addJOb(data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`addJobByAdmin`, data);
      dispatch(addJObAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getCompanyAction(data) {
  return {
    type: "GETCOMPANY",
    getcompany: data,
  };
}
export function getCompany() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`allPendingCompanies`);
      dispatch(getCompanyAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function approvecompanyAction(data) {
  console.log("app com data:", data);
  return {
    type: "APPROVECOMPANY",
    approvecompany: data,
  };
}
export function approvecompany() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getAllCompanies`);
      dispatch(approvecompanyAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function getCategoryAction(data) {
  return {
    type: "GETCATEGORY",
    getCategory: data,
  };
}
export function getCategory() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getAllCategories`);
      dispatch(getCategoryAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getAllApprovedAccountsAction(data) {
  return {
    type: "ACCOUNTS",
    payload: data,
  };
}
export function getAllApprovedAccounts() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvedAccounts`);
      dispatch(getAllApprovedAccountsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getAllPendingAccountsAction(data) {
  return {
    type: "PENDINGACCOUNTS",
    pendingaccounts: data,
  };
}
export function getAllPendingAccounts() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`allPendingAccounts`);
      dispatch(getAllPendingAccountsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getJOBAction(data) {
  return {
    type: "GETJOB",
    getjob: data,
  };
}
export function getJOB() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getAllJobs`);
      dispatch(getJOBAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getBlogAction(data) {
  return {
    type: "GETBLOG",
    getBlog: data,
  };
}
export function getBLOG() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getAllBlogs`);
      dispatch(getBlogAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function updateJObAction(data) {
  return {
    type: "UPDATEJOB",
    updatejob: data,
  };
}
export function updateJOb(id, data) {
  console.log(id, "id is here update");
  console.log(data, "data is here update");
  return async (dispatch) => {
    try {
      const res = await axiosClient().put(`updateJobById/${id}`, data);
      dispatch(updateJObAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function updatenewsAction(data) {
  return {
    type: "UPDATENEWS",
    updatenews: data,
  };
}
export function updatenews(id, data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().put(`updateNewsById/${id}`, data);
      dispatch(updatenewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getpendingJOBAction(data) {
  return {
    type: "GETPENDINGJOB",
    getpendingjob: data,
  };
}
export function getPndingJOB() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`allPendingJobs`);
      dispatch(getpendingJOBAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getpendingBLOGAction(data) {
  return {
    type: "GETPENDINGBLOG",
    getpendingblog: data,
  };
}
export function getPndingBLOG() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`allPendingBlogs`);

      dispatch(getpendingBLOGAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function EditJobAction(data) {
  return {
    type: "EDITJOB",
    editjob: data,
  };
}
export function EditJob(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getJobById/${id}`);
      dispatch(EditJobAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function viewNewsAction(data) {
  return {
    type: "EDITNEWS",
    editnewss: data,
  };
}
export function viewNews(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getNewsById/${id}`);
      dispatch(viewNewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function viecompanyAction(data) {
  return {
    type: "VIEWCOMPANY",
    viewcompany: data,
  };
}
export function viecompany(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getCompanyById/${id}`);
      dispatch(viecompanyAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ViewNewsAction(data) {
  return {
    type: "VIEWNEWS",
    viewnews: data,
  };
}
export function ViewNews(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getNewsById/${id}`);
      dispatch(ViewNewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function ViewBlogsAction(data) {
  return {
    type: "VIEWBLOGS",
    viewBlogs: data,
  };
}
export function ViewBlogs(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getBlogById/${id}`);
      dispatch(ViewBlogsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getPndingNewsAction(data) {
  return {
    type: "PENDINGNEWS",
    pendingnews: data,
  };
}
export function getPndingNews() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`allPendingNews`);
      dispatch(getPndingNewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function getNewsAction(data) {
  return {
    type: "ALLNEWS",
    allnews: data,
  };
}
export function getNews() {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`getAllNewsAdmin`);
      dispatch(getNewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function addnewsAction(data) {
  return {
    type: "ADDNEWS",
    addnews: data,
  };
}
export function addnews(data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`addNewsByAdmin`, data);
      dispatch(addnewsAction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
//Delete/DeleteRecord/DeleteJob/DeleteAccount
function DeleteAccountaction(data) {
  return {
    type: "DELETEACCOUNT",
    deleteaccount: data,
  };
}
export function DeleteAccount(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`rejectAccount/${id}`);
      dispatch(DeleteAccountaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function DeleteJobaction(data) {
  return {
    type: "DELETEJOB",
    deleteJob: data,
  };
}
export function DeleteJob(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`removeJobById/${id}`);
      dispatch(DeleteJobaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function DeleteBlogaction(data) {
  return {
    type: "DELETEBLOG",
    deleteBlog: data,
  };
}
export function DeleteBlog(slug) {
  console.log("slug:", slug);
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`removeBlogById/${slug}`);
      dispatch(DeleteBlogaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function DeleteNewsaction(data) {
  return {
    type: "DELETENEWS",
    deletenews: data,
  };
}
export function DeleteNews(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`removeNewsById/${id}`);
      dispatch(DeleteNewsaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function DeleteCategoryaction(data) {
  return {
    type: "DELETECATEGORY",
    deletecategory: data,
  };
}
export function DeleteCategory(id) {
  console.log("1");
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`removeCategoryById/${id}`);
      dispatch(DeleteCategoryaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function DeleteRecordaction(data) {
  return {
    type: "DELETERECORD",
    deleterecord: data,
  };
}
export function DeleteRecord(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`rejectCompany/${id}`);
      dispatch(DeleteRecordaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ApproveAccountaction(data) {
  return {
    type: "APPROVEACCOUNT",
    approveaccount: data,
  };
}
export function ApproveAccount(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvePendingAccount/${id}`);
      dispatch(ApproveAccountaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ApproveRecordaction(data) {
  return {
    type: "APPROVERECORD",
    approverecord: data,
  };
}
export function ApproveRecord(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvePendingCompany/${id}`);
      dispatch(ApproveRecordaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ApproveJobaction(data) {
  return {
    type: "APPROVEJOB",
    approvejob: data,
  };
}
export function ApproveJob(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvePendingJob/${id}`);
      dispatch(ApproveJobaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ApproveNewsaction(data) {
  return {
    type: "APPROVENEWS",
    approvenews: data,
  };
}
export function ApproveNews(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvePendingNews/${id}`);
      dispatch(ApproveNewsaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}
function ApproveBlogsaction(data) {
  return {
    type: "APPROVEBLOGS",
    approveBlogs: data,
  };
}
export function ApproveBlogs(id) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().get(`approvePendingBlog/${id}`);
      dispatch(ApproveBlogsaction(res.data));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function logoutAction() {
  localStorage.removeItem("token");
  localStorage.removeItem("expiresIn");
  return {
    type: "LOGOUT",
  };
}
function checkAuthTimeOut(expTime) {
  return function (dispatch) {
    setTimeout(() => {
      dispatch(logoutAction());
    }, expTime);
  };
}
export function CheckAuthState() {
  return function (dispatch) {
    const accessToken = localStorage.getItem("token") || "";
    if (!accessToken) {
      dispatch(logoutAction());
    } else {
      const expiresIn = localStorage.getItem("expiresIn") || "0";
      const expirationDate = new Date(parseInt(expiresIn));
      if (expirationDate < new Date()) {
        dispatch(logoutAction());
      } else {
        const data = { jwtToken: accessToken };
        axiosClient()
          .post("/checkUserStatus", data)
          .then((res) => {
            console.log(res.data.user._id);
            dispatch(
              loginAction(
                data.jwtToken,
                res.data.user._id,
                res.data.user.username,
                res.data.user.email,
                res.data.user.role
              )
            );
          })
          .catch((err) => {
            dispatch(logoutAction());
          });
        dispatch(
          checkAuthTimeOut(expirationDate.getTime() - new Date().getTime())
        );
      }
    }
  };
}
