import axios from "axios";
import Cookie from "cookie-universal";

const cookies = Cookie();
let BASE_URL = "";

console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
  // BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
  // BASE_URL = "https://nursing-b.herokuapp.com/";
  // BASE_URL = "http://de35-182-185-206-125.ngrok.io/";
}

if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
}

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  let instance = axios.create(defaultOptions);
  axios.defaults.headers.common = {
    "x-auth-token": `${cookies.get("accessToken")}`,
  };

  instance.interceptors.request.use(function (config) {
    // config.headers.Authorization = cookies.get("accessToken");
    config.headers.common = { "x-auth-token": `${cookies.get("accessToken")}` };
    return config;
  });

  // instance.defaults.headers.common[
  //   "Authorization"
  // ] = store.getState().mainStore.accessToken;

  return instance;
}
