import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CheckAuthState } from "./action";
import Notification from "./views/notifications/Notification";
import Cookie from "cookie-universal";
import "./scss/style.scss";

const cookies = Cookie();
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// const TheLayoutAdmin = React.lazy(() => import('./AdminCompoenet/containers/TheLayout'));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));

function App() {
  const store = useSelector((state) => state.appReducer, shallowEqual);

  // useEffect(() => {
  // console.log("Hello from useEffect")
  //   console.log(cookies.get("accessToken"), "cookies")
  //   dispatch(CheckAuthState());
  // }, [dispatch]);

  let routes = (
    <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
  );

  if (
    typeof cookies.get("accessToken") == "undefined" ||
    cookies.get("accessToken") === ""
  ) {
    routes = (
      <Switch>
        <Route
          exact
          path="/login"
          name="Login Page"
          render={(props) => <Login {...props} />}
        />
        <Redirect to={"/login"} />
      </Switch>
    );
  }

  return (
    <div>
      <HashRouter>
        <React.Suspense fallback={loading}>{routes}</React.Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
