import { createStore, combineReducers } from "redux";
// import { combineReducers } from "redux";

// const appState = {
//   message: "",
//   accessToken: "",
//   userId: "",
//   username: "",
//   email: "",
//   role: "",
//   sidebarShow: 'responsive',
// };

const initialState = {
  message: "",
  accessToken: "",
  userId: "",
  username: "",
  email: "",
  role: "",
  sidebarShow: "responsive",
  UserData: "",
  CompanyData: "",
  GetCompany: [],
  CategoryData: "",
  getCategoryData: [],
  jobresponce: "",
  getjobresponce: [],
  getBlogresponce: [],
  accountsresponce: [],
  approveresponce: [],
  approverecord: "",
  deleteRecordresponse: "",
  deletecategoryresponce: "",
  deleteJObresponse: "",
  deleteBlogresponse: "",
  getpendingresponce: [],
  ApproveJobResponse: "",
  ApproveBlogResponse: "",
  pendingaccountsresponse: [],
  deleteaccountresponse: "",
  approveaccountresponse: "",
  editjobresponse: "",
  pendingnewsresponse: [],
  allnewsresponse: [],
  addnewsresponce: "",
  deletenewsresponse: "",
  approvenewsresponse: "",
  updatejobresponce: "",
  viewnewsresponce: "",
  updatenewsresponce: "",
  editnewsresponse: "",
  viewcompanyresponse: "",
  updatecompanyresponce: "",
  viewBlogsresponce: "",
};

// const appReducer = (state = appState, action) => {
//   switch (action.type) {
//     case "APP_STATUS":
//       return {
//         ...state,
//         message: action.message,
//       };
//     case "LOGIN": {
//       return {
//         ...state,
//         accessToken: action.accessToken,
//         userId: action.userId,
//         username: action.username,
//         email: action.email,
//         role: action.role,
//       };
//     }
//     default:
//       return {
//         ...state,
//       };
//   }
// };

export function appStatusAction(message) {
  return {
    type: "APP_STATUS",
    message: message,
  };
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case "APP_STATUS":
      return {
        ...state,
        message: action.message,
      };
    case "set":
      return { ...state, ...action.rest };
    case "APP_STATUS":
      return {
        ...state,
        message: action.message,
      };
    case "LOGIN": {
      return {
        ...state,
        accessToken: action.accessToken,
        userId: action.userId,
        username: action.username,
        email: action.email,
        role: action.role,
      };
    }
    case "USERS": {
      var newData = [...state.accountsresponce, action.user.data];

      return {
        ...state,
        UserData: action.user,
        accountsresponce: newData,
      };
    }
    case "COMPANY": {
      var newData = [...state.approveresponce, action.company.data];
      return {
        ...state,
        CompanyData: action.company,
        approveresponce: newData,
      };
    }
    case "ADDNEWS": {
      var newData = [...state.allnewsresponse, action.addnews.data];
      return {
        ...state,
        addnewsresponce: action.addnews,
        allnewsresponse: newData,
      };
    }
    case "GETCOMPANY": {
      return {
        ...state,
        GetCompany: action.getcompany.data,
      };
    }
    case "CATEGORY": {
      var newData = [...state.getCategoryData, action.category.data];
      return {
        ...state,
        CategoryData: action.category,
        getCategoryData: newData,
      };
    }
    case "GETCATEGORY": {
      return {
        ...state,
        getCategoryData: action.getCategory.data,
      };
    }
    case "JOB": {
      var newData = [...state.getjobresponce, action.job.data];
      return {
        ...state,
        jobresponce: action.job,
        getjobresponce: newData,
      };
    }
    case "UPDATEJOB": {
      var newData = state.getpendingresponce.findIndex(
        (item) => item._id === action.updatejob.data._id
      );
      console.log(action.updatejob.data._id, "id index");
      console.log(state.getpendingresponce, "state index");
      console.log(newData, " index");
      state.getpendingresponce[newData] = action.updatejob.data;
      return {
        ...state,
        updatejobresponce: action.updatejob,
      };
    }
    case "UPDATENEWS": {
      var newData = state.pendingnewsresponse.findIndex(
        (item) => item._id === action.updatenews.data._id
      );

      state.pendingnewsresponse[newData] = action.updatenews.data;
      return {
        ...state,
        updatenewsresponce: action.updatenews,
      };
    }
    case "UPDATECOMPANY": {
      var newData = state.GetCompany.findIndex(
        (item) => item._id === action.updatecompany.data._id
      );

      state.GetCompany[newData] = action.updatecompany.data;
      return {
        ...state,
        updatecompanyresponce: action.updatecompany,
      };
    }
    case "VIEWNEWS": {
      return {
        ...state,
        viewnewsresponce: action.viewnews.data,
      };
    }
    case "VIEWBLOGS": {
      return {
        ...state,
        viewBlogsresponce: action.viewBlogs.data,
      };
    }

    case "GETJOB": {
      return {
        ...state,
        getjobresponce: action.getjob.data,
      };
    }
    case "GETBLOG": {
      return {
        ...state,
        getBlogresponce: action.getBlog.data,
      };
    }
    case "GETPENDINGJOB": {
      console.log("action.getpendingjob.data:", action.getpendingjob.data);
      return {
        ...state,
        getpendingresponce: action.getpendingjob.data,
      };
    }
    case "GETPENDINGBLOG": {
      return {
        ...state,
        getPendingBlogs: action.getpendingblog.data,
      };
    }
    case "ACCOUNTS": {
      return {
        ...state,
        accountsresponce: action.payload.data,
      };
    }
    case "APPROVECOMPANY": {
      return {
        ...state,
        approveresponce: action.approvecompany.data,
      };
    }
    case "APPROVERECORD": {
      var newData = [...state.approveresponce, action.approverecord.data];
      return {
        ...state,
        approverecord: action.approverecord,
        GetCompany: state.GetCompany.filter(
          (item) => item._id !== action.approverecord.data._id
        ),
        approveresponce: newData,
      };
    }
    case "DELETERECORD": {
      return {
        ...state,
        deleteRecordresponse: action.deleterecord,
        GetCompany: state.GetCompany.filter(
          (item) => item._id !== action.deleterecord.data._id
        ),
        approveresponce: state.approveresponce.filter(
          (item) => item._id !== action.deleterecord.data._id
        ),
      };
    }
    case "DELETECATEGORY": {
      return {
        ...state,
        deletecategoryresponce: action.deletecategory,
        getCategoryData: state.getCategoryData.filter(
          (item) => item._id !== action.deletecategory.data._id
        ),
      };
    }
    case "DELETEJOB": {
      return {
        ...state,
        deleteJObresponse: action.deleteJob,
        getjobresponce: state.getjobresponce.filter(
          (item) => item._id !== action.deleteJob.data._id
        ),
        getpendingresponce: state.getpendingresponce.filter(
          (item) => item._id !== action.deleteJob.data._id
        ),
      };
    }
    case "DELETEBLOG": {
      return {
        ...state,
        deleteBlogresponse: action.deleteBlog,
        getBlogresponce: state.getBlogresponce.filter(
          (item) => item._id !== action.deleteBlog.data._id
        ),
        getpendingresponce: state.getpendingresponce.filter(
          (item) => item._id !== action.deleteBlog.data._id
        ),
      };
    }
    case "DELETENEWS": {
      return {
        ...state,
        deletenewsresponse: action.deletenews,
        allnewsresponse: state.allnewsresponse.filter(
          (item) => item._id !== action.deletenews.data._id
        ),
        pendingnewsresponse: state.pendingnewsresponse.filter(
          (item) => item._id !== action.deletenews.data._id
        ),
      };
    }
    case "APPROVEJOB": {
      var newData = [...state.getjobresponce, action.approvejob.data];

      return {
        ...state,
        ApproveJobResponse: action.approvejob,
        getpendingresponce: state.getpendingresponce.filter(
          (item) => item._id !== action.approvejob.data._id
        ),
        getjobresponce: newData,
      };
    }
    case "APPROVEBLOGS": {
      var newData = [...state.getBlogresponce, action.approveBlogs.data];

      return {
        ...state,
        ApproveBlogResponse: action.approveBlogs,
        getpendingresponce: state.getpendingresponce.filter(
          (item) => item._id !== action.approveBlogs.data._id
        ),
        getBlogresponce: newData,
      };
    }
    case "APPROVENEWS": {
      var newData = [...state.allnewsresponse, action.approvenews.data];

      return {
        ...state,
        approvenewsresponse: action.approvenews,
        pendingnewsresponse: state.pendingnewsresponse.filter(
          (item) => item._id !== action.approvenews.data._id
        ),
        allnewsresponse: newData,
      };
    }
    case "PENDINGACCOUNTS": {
      console.log("action.pendingaccounts.data: ", action.pendingaccounts.data);
      return {
        ...state,
        pendingaccountsresponse: action.pendingaccounts.data,
      };
    }
    case "PENDINGNEWS": {
      return {
        ...state,
        pendingnewsresponse: action.pendingnews.data,
      };
    }
    case "ALLNEWS": {
      return {
        ...state,
        allnewsresponse: action.allnews.data,
      };
    }
    case "DELETEACCOUNT": {
      return {
        ...state,
        deleteaccountresponse: action.deleteaccount,
        accountsresponce: state.accountsresponce.filter(
          (item) => item._id !== action.deleteaccount.data._id
        ),
        pendingaccountsresponse: state.pendingaccountsresponse.filter(
          (item) => item._id !== action.deleteaccount.data._id
        ),
      };
    }
    case "APPROVEACCOUNT": {
      var newData = [...state.accountsresponce, action.approveaccount.data];
      return {
        ...state,
        approveaccountresponse: action.approveaccount,
        pendingaccountsresponse: state.pendingaccountsresponse.filter(
          (item) => item._id !== action.approveaccount.data._id
        ),
        accountsresponce: newData,
      };
    }
    case "EDITJOB": {
      return {
        ...state,
        editjobresponse: action.editjob.data,
      };
    }
    case "EDITNEWS": {
      return {
        ...state,
        editnewsresponse: action.editnewss.data,
      };
    }
    case "VIEWCOMPANY": {
      return {
        ...state,
        viewcompanyresponse: action.viewcompany.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

// const store = createStore(changeState)
export default changeState;

// const reducers = combineReducers({
//   appReducer,
// });

// export default reducers;
